.markdown-content img {
    display: block;
    margin: 0 auto;
    max-width: 30%;  /* Ensures the image doesn't overflow the container */
    height: auto;  /* Maintains aspect ratio */
    border-radius: 10px;
  }
/* Default image style (centered) */
img.markdown-image {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 30%;
}

/* Left-aligned image */
img.markdown-image-left {
  float: left;
  margin-right: 15px;
  max-width: 20%;
}

/* Right-aligned image */
img.markdown-image-right {
  float: right;
  margin-left: 5px;
  max-width: 20%;
}

.post-header {
  display: flex;
  justify-content: space-between; /* Ensures the name is on the left and the date on the right */
  align-items: center; /* Vertically aligns items in the center */
  margin-top: 10px;
  margin-bottom: 20px;
}


.hashtag-button {
  background: none;
  border: none;
  color: #007bff;
  text-decoration: none;
  cursor: pointer;
}
.hashtag-button:hover {
  text-decoration: underline;;
}

.read-more {
  margin-bottom: 20px;
}

.clearfix::after {
  content: "";
  display: table;
  clear: both;
}

.sticky-element {
  position: sticky;
  top: 0; /* Sticks the element at the top of its container */
  z-index: 100; /* Ensure it stays above other elements */
  padding: 10px;
}