body, html {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
  }

  .container {
    min-height: 70vh;
  }
  
  /* Flex container */
  .app-container {
    display: flex;
    justify-content: space-between;  /* Distribute items evenly */
    gap: 20px;  /* Optional: add space between the components */
    width: 100%;  /* Ensure the container takes full width */
    padding: 0;
    margin: 0;
   
  }
  
  /* First child: 25% width */
  .app-container > div:first-child {
    flex: 0 0 20%; /* Flex-grow: 0, Flex-shrink: 0, Flex-basis: 25% */
    padding: 0;
    margin: 0;
  }
  
  /* Second child (PostOverview): 50% width */
  .app-container > div:nth-child(2) {
    flex: 0 0 50%; /* Flex-grow: 0, Flex-shrink: 0, Flex-basis: 50% */
    padding: 0;
    margin: 0;
  }
  
  /* Third child (PostList): 25% width */
  .app-container > div:nth-child(3) {
    flex: 0 0 25%; /* Flex-grow: 0, Flex-shrink: 0, Flex-basis: 25% */
    padding: 0;
    margin: 0;
  }
  
  @media only screen and (min-width: 769px) {
    .show-hide-btn {
      display: none !important; /* Hide the button on larger screens */
    }
  }
  
  /* Adjust for smaller screens */
  @media (max-width: 768px) {
    .app-container {
      flex-direction: column;  /* Stack components vertically */
      gap: 10px;  /* Adjust gap for mobile */
    }
  
    .app-container > div {
      width: 100%;  /* Ensure each component takes full width on mobile */
    }
    img.markdown-image-left,
    img.markdown-image-right {
    float: none;
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
  }

  .toggle-div {
    display: none;
    transition: all 0.3s ease;
  }

  .show {
    display: block;
  }

  .show-hide-btn {
    display: block;
  }
}