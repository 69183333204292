/* Custom CSS for off-canvas */
.custom-offcanvas {
    border-radius: 15px 0 0 15px; /* Rounded corners on the left */
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2); /* Add a shadow effect */
  }
  
  /* Override default backdrop to be more transparent */
  .offcanvas-backdrop {
    background-color: rgba(0, 0, 0, 0.623) !important; /* Set backdrop to a lighter color */
  }
  
  @media (max-width: 768px) {
    .custom-offcanvas {
      width: 20%; /* Gör den bredare på mindre skärmar */
    }
  }

  /* För större skärmar */
@media (min-width: 992px) {
  .custom-offcanvas {
    width: 300px !important; /* Ange en fast bredd för stora skärmar */
    border-radius: 3; /* Anpassa rundningen om det behövs */
  }
  
}

 