/* Fullscreen header initially */
.header-background {
  position: relative;
  width: 100%;
  height: 100vh;  /* Full screen initially */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background: linear-gradient(135deg, #0f2027, #133c49, #2c5364);  /* Same futuristic gradient */
  font-family: 'Orbitron', sans-serif;
  color: #fff;
  z-index: 0;
  transition: height 0.3s ease;  /* Smooth transition for height change */
}

/* Text content */
.text-content {
  position: absolute;
  top: 50%;  /* Start in the middle */
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  transition: top 0.3s ease;  /* Smoothly move the text position */
}

.text-content h1 {
  color: #00d4ff;
  text-shadow: 0px 0px 15px #00d4ff, 0px 0px 20px #00ffff;
  letter-spacing: 2px;
  margin: 0;
  animation: neonGlow 2s ease-in-out infinite alternate;
  transition: font-size 0.3s ease;
}

.text-content p {
  color: #d3f9ff;
  text-shadow: 0px 0px 10px #00ffff;
  letter-spacing: 1px;
  margin-top: 10px;
  transition: font-size 0.3s ease;
}

/* Glow animation */
@keyframes neonGlow {
  from {
    text-shadow: 0px 0px 15px #00d4ff, 0px 0px 20px #00ffff;
  }
  to {
    text-shadow: 0px 0px 25px #00ffff, 0px 0px 30px #00d4ff;
  }
}

/* Background effect with futuristic lines */
.header-background::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: repeating-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.05),
    rgba(255, 255, 255, 0.05) 1px,
    transparent 1px,
    transparent 20px
  );
  opacity: 0.3;
  animation: moveLines 10s linear infinite;
}

/* Move diagonal lines */
@keyframes moveLines {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 100px 100px;
  }
}
